export default {
  "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio profilo"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglese"])},
  "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
  "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizioni"])},
  "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In corso"])},
  "inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox"])},
  "not_compliant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non conforme"])},
  "choosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scelti"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli"])},
  "cloned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clonati"])},
  "manual_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiunta manuale"])},
  "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica"])},
  "table_info_pagination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visualizzazione da ", _interpolate(_named("start")), " a ", _interpolate(_named("end")), " di ", _interpolate(_named("total")), " voci"])},
  "table_select_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("numberSelected")), " righe selezionate"])},
  "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In arrivo"])},
  "application_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature chiuse"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scaduto"])},
  "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviato"])},
  "position_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione / Azienda"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione"])},
  "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data d'inizio"])},
  "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scadenza"])},
  "applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidati"])},
  "applicants_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("number")), " candidati"])},
  "timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo"])},
  "all_applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i candidati"])},
  "pipeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pipeline"])},
  "of_the_time_has_passed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il ", _interpolate(_named("percentage")), "% del tempo è trascorso"])},
  "current_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione corrente"])},
  "job_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizioni di lavoro"])},
  "position_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio posizione"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condivisione"])},
  "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])},
  "total_applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale candidati"])},
  "share_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa sezione trovi gli strumenti per pubblicare e condividere la posizione su altre piattaforme e job board."])},
  "complete_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL completa"])},
  "short_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL breve"])},
  "copy_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia URL"])},
  "qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermato"])},
  "url_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'URL è stato copiato negli appunti!"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione"])},
  "add_applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi candidati"])},
  "manual_add_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi manualmente i contatti che potrebbero essere interessati a questa posizione per invitarli successivamente ad candidarsi."])},
  "csv_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita a candidarsi"])},
  "csv_import_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa una lista di contatti per invitarli massivamente a candidarsi a questa posizione."])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "add_and_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi e chiudi"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
  "select_file_to_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il file da caricare"])},
  "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successo"])},
  "operation_successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazione completata con successo"])},
  "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcosa è andato storto"])},
  "success_text_invite_to_apply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invitati: ", _interpolate(_named("invited")), ", Non invitati: ", _interpolate(_named("notInvited")), ", Duplicati: ", _interpolate(_named("duplicated"))])},
  "not_in_pipeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non in pipeline"])},
  "move_in_pipeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muovi in pipeline"])},
  "invite_to_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita a completare la candidatura"])},
  "invite_to_video_interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invito alla video intervista"])},
  "applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidato"])},
  "application_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di applicazione"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
  "back_to_candidates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna ai candidati"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
  "bulk_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona candidato/i e scegli un'azione"])},
  "data_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilità dati"])},
  "internal_dataset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Dataset"])},
  "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domande"])},
  "questions_max_tolal_score": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Risposte (max ", _interpolate(_named("maxScore")), " pt.)"])},
  "single_question_score": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("score")), " pt."])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappa"])},
  "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "previews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteprime"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo"])},
  "not_compiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non compilato"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteprima"])},
  "missing_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File mancante"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista"])},
  "error_create_application_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La candidatura esiste già"])},
  "error_create_application_position_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione candidatura: Posizione archiviata"])},
  "error_create_application_invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione candidatura: Email non valida"])},
  "error_create_application_in_deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il candidato è in cancellazione dal sistema"])},
  "error_application_move_in_pipeline_missing_mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaggio di pipeline obbligatorio mancante"])},
  "error_application_update_field_required_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il valore non può essere nullo"])},
  "error_application_update_field_external_invitation_present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è possibile aggiornare un campo se presente un invito alla compilazione di un utente esterno"])},
  "error_application_update_file_field_missing_argument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessario passare nome e base64 per il file"])},
  "error_generic_wrong_base_64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il file in base64 non è in formato base64"])},
  "invalid_username_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome utente o password errati"])},
  "profile_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo aggiornato"])},
  "forgot_password_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazione riuscita, riceverai le istruzioni per recuperare la tua password all'indirizzo email con cui sei stato registrato"])},
  "set_new_password_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazione riuscita, la tua password è stata impostata con successo"])},
  "password_and_password_confirmation are_different": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password e la conferma della password sono diverse"])},
  "invalid_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password non valida"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome utente"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "forgot_password_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha dimenticato la password?"])},
  "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
  "please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendere prego"])},
  "enter_your_email_to_reset_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua email per reimpostare la password"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
  "setup_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta nuova password"])},
  "choose_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la tua nuova password: deve contenere minimo 7 caratteri e almeno una lettera minuscola, una maiuscola, un numero e un carattere speciale."])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password"])},
  "warning_min_selected_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seleziona almeno ", _interpolate(_named("number")), " candidato/i!"])},
  "recruiting_inviting_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ricerca assegnata da ", _interpolate(_named("recruiter")), " (", _interpolate(_named("email")), ")."])},
  "no_recruitment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembra che al momento non hai ricerche assegnate. Rilassati."])},
  "no_recruitment_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarà nostra premura avvisarti non appena riceverai un nuovo incarico."])},
  "warning_pipeline_automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo step ha un processo di automazione della pipeline"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
  "selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione"])},
  "404_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembra che qui non ci sia niente."])},
  "404_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla dashboard"])},
  "success_text_import_csv": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utenti totali: ", _interpolate(_named("total")), ", Importati: ", _interpolate(_named("imported")), ", Righe non valide: ", _interpolate(_named("invalid")), ", Duplicati: ", _interpolate(_named("duplicated")), ", In cancellazione: ", _interpolate(_named("deletion"))])},
  "label_radio_single_insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserimento singolo"])},
  "label_radio_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV Import"])},
  "text_create_applications_from_file_download_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizza questa funzionalità per poter importare le candidature senza CV ma con relativi dataset collegati. Seleziona il file da caricare (*.csv, max 1000 righe, nome cognome e email obbligatori). È necessario utilizzare il modello al link di seguito per avere il riferimento ai campi della posizione."])},
  "link_download_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica Esempio"])},
  "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa"])},
  "import_and_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa e chiudi"])},
  "text_invite_by_file_download_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il file da caricare (*.csv, max 1000 righe, nome e cognome facoltativi, email obbligatoria)"])},
  "link_download_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica Modello"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "quick_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidatura veloce"])},
  "invalid_reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mail inserita non è registrata"])},
  "already_request_reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta già inviata, controlla la tua mail"])},
  "delete_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella candidature"])},
  "success_text_delete_applicants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Totali: ", _interpolate(_named("total")), ", Cancellati: ", _interpolate(_named("deleted")), ", Non cancellati: ", _interpolate(_named("not_deleted")), ".<br>Le candidature pervenute da un altro contesto, non possono essere cancellate"])},
  "are_you_sure_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro?"])},
  "delete_confirmation_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inserisci la parola \"", _interpolate(_named("delete")), "\" per procedere"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancella"])},
  "wrong_input_word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La parola inserita non è corretta"])},
  "delection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellazione"])},
  "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendi"])},
  "elaborating_action_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiamo elaborando l'azione..."])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tedesco"])},
  "loading_completed_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento completato. Riceverai una mail quando i file saranno stati tutti processati."])},
  "uploaded_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricati:"])},
  "error_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore:"])},
  "importing_three_dots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importazione in corso..."])},
  "ai_import_progress_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendi il caricamento di tutti i CV che hai selezionato in questa importazione; una volta completato, potrai chiudere questa finestra e continuare a lavorare. La conclusione della seguente importazione verrà notificata via e-mail."])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
  "wait_three_dots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendi..."])},
  "ai_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Import"])},
  "select_cvs_to_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona i CV da importare"])},
  "ai_cvs_to_import_decription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seleziona i CV da importare e successivamente sarà l'AI ad estrarre i dati principali dal CV come: Nome, Cognome, E-mail , Numero di telefono, Indirizzo di residenza e predisporli, con il relativo CV, nella scheda candidato. Seleziona al massimo ", _interpolate(_named("maxfile")), " file, ciascuno con peso inferiore a ", _interpolate(_named("size")), " Mb. I formati supportati sono ", _interpolate(_named("formats")), "."])},
  "start_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizia importazione"])},
  "single_import_decription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizza questa funzionalità per importare solo le informazioni di base (nome, cognome ed e-mail) di una singola candidatura. Successivamente potrai caricare, nella sua scheda di candidatura, tutte le altre informazioni come ad esempio il file CV, domicilio, etc."])},
  "select_file_to_upload_csv_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il file in formato CSV da importare"])},
  "error_file_to_upload_ai_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero dei file caricati è superiore al limite consentito."])},
  "ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ai"])},
  "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome completo"])},
  "allowed_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato file supportato: png, jpg, jpeg"])},
  "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
  "profile_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli Profilo"])},
  "change_avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia avatar"])},
  "hidden_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidatura nascosta dal recruiter"])}
}