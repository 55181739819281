import { defineStore } from "pinia";
import { User } from "@/core/types/UserType";

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      access_token: null as string | null,
      refresh_token: null as string | null,
      user: {} as User,
    };
  },
  actions: {
    login(access_token: string, refresh_token: string) {
      this.access_token = access_token;
      this.refresh_token = refresh_token;
    },
    logout() {
      this.access_token = null;
      this.refresh_token = null;
      this.user = {} as User;
    },
    isLoggedIn() {
      return this.access_token !== null;
    },
    setUser(user: User) {
      this.user = user;
    },
  },
  persist: true,
});
