export default {
  "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
  "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positions"])},
  "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox"])},
  "not_compliant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not compliant"])},
  "choosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choosed"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
  "cloned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloned"])},
  "manual_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual add"])},
  "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
  "table_info_pagination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing ", _interpolate(_named("start")), " to ", _interpolate(_named("end")), " of ", _interpolate(_named("total")), " entries"])},
  "table_select_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("numberSelected")), " rows selected"])},
  "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
  "application_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications closed"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
  "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
  "position_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position / Company"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
  "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
  "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
  "applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicants"])},
  "applicants_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("number")), " applicants"])},
  "timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timing"])},
  "all_applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All applicants"])},
  "pipeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pipeline"])},
  "of_the_time_has_passed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), "% of the time has passed"])},
  "current_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current position"])},
  "job_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job positions"])},
  "position_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position detail"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "total_applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total applicants"])},
  "share_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section you will find the tools to be able to publish and share the position on other platforms and job boards."])},
  "complete_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete URL"])},
  "short_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short URL"])},
  "copy_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy URL"])},
  "qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
  "url_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL has been copied to clipboard!"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "add_applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add applicants"])},
  "manual_add_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually add contacts who may be interested in this position to invite them to apply later."])},
  "csv_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite to apply"])},
  "csv_import_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import a list of contacts to be massively invited to apply for this position."])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "add_and_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add and close"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "select_file_to_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the file to upload"])},
  "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "operation_successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation successful"])},
  "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
  "success_text_invite_to_apply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invited: ", _interpolate(_named("invited")), ", Not invited: ", _interpolate(_named("notInvited")), ", Duplicated: ", _interpolate(_named("duplicated"))])},
  "not_in_pipeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not in pipeline"])},
  "move_in_pipeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move in pipeline"])},
  "invite_to_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite to complete application"])},
  "invite_to_video_interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite to video interview"])},
  "applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant"])},
  "application_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application date"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
  "back_to_candidates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to applicants"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "bulk_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select applicant/s and choose an action"])},
  "data_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data visibility"])},
  "internal_dataset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal dataset"])},
  "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions"])},
  "questions_max_tolal_score": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Answers (max ", _interpolate(_named("maxScore")), " pts.)"])},
  "single_question_score": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("score")), " pts."])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
  "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "previews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previews"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "not_compiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Compiled"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "missing_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing file"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
  "error_create_application_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application already exists"])},
  "error_create_application_position_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application creation: Archived position"])},
  "error_create_application_invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application creation: Invalid email"])},
  "error_create_application_in_deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application is being canceled"])},
  "error_application_move_in_pipeline_missing_mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing mandatory step in pipeline"])},
  "error_application_update_field_required_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value cannot be null"])},
  "error_application_update_field_external_invitation_present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't update a field when there is an external invitation"])},
  "error_application_update_file_field_missing_argument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't pass only file name or only base64 file"])},
  "error_generic_wrong_base_64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base64 file does not contain base64 content"])},
  "invalid_username_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid username or password"])},
  "profile_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile updated"])},
  "forgot_password_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation completed successfully, you will receive instructions to recover your password at the email address you have been registered with"])},
  "set_new_password_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation successful, your password was successfully set"])},
  "password_and_password_confirmation are_different": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password and password confirmation are different"])},
  "invalid_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "forgot_password_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
  "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait"])},
  "enter_your_email_to_reset_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email to reset your password"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "setup_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup new password"])},
  "choose_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your new password: it must contain minimum 7 characters and at least one lowercase letter, one uppercase letter, one number and one special character."])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
  "warning_min_selected_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please select at least ", _interpolate(_named("number")), " application/s!"])},
  "recruiting_inviting_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recruitment assigned by ", _interpolate(_named("recruiter")), " (", _interpolate(_named("email")), ")."])},
  "no_recruitment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It looks like you don't currently have any recruitment assigned. Take it easy."])},
  "no_recruitment_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will promptly notify you as soon as you receive a new assignment."])},
  "warning_pipeline_automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This step has a pipeline automation process"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection"])},
  "404_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seems there is nothing here."])},
  "404_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to the dashboard"])},
  "success_text_import_csv": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total users: ", _interpolate(_named("total")), ", Imported: ", _interpolate(_named("imported")), ", Invalid rows: ", _interpolate(_named("invalid")), ", Duplicated: ", _interpolate(_named("duplicated")), ", Deleting: ", _interpolate(_named("deletion"))])},
  "label_radio_single_insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single entry"])},
  "label_radio_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV Import"])},
  "text_create_applications_from_file_download_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this feature to be able to import applications without CVs but with linked datasets. Select file to upload (*.csv, max. 1000 lines, name, surname and email required). You must use the template at the link below to reference the position fields."])},
  "link_download_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Example"])},
  "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
  "import_and_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import and close"])},
  "text_invite_by_file_download_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file to upload (*.csv, max. 1000 lines, name and surname optional, email required)"])},
  "link_download_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Model"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "quick_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick apply"])},
  "invalid_reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email entered is not registered"])},
  "already_request_reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request already sent, check your email"])},
  "delete_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete applications"])},
  "success_text_delete_applicants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total: ", _interpolate(_named("total")), ", Deleted: ", _interpolate(_named("deleted")), ", Not deleted: ", _interpolate(_named("not_deleted")), ".<br>Applications received from another context cannot be deleted"])},
  "are_you_sure_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
  "delete_confirmation_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enter the word \"", _interpolate(_named("delete")), "\" to proceed"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
  "wrong_input_word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The word entered is incorrect"])},
  "delection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion"])},
  "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait"])},
  "elaborating_action_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are processing the action..."])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "loading_completed_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading completed. You will receive an email when the files have all been processed."])},
  "uploaded_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploded:"])},
  "error_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error:"])},
  "importing_three_dots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importing..."])},
  "ai_import_progress_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for all the CVs you selected in this import to load; once completed, you can close this window and continue working. The completion of the following import will be notified via email."])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "wait_three_dots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait..."])},
  "ai_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Import"])},
  "select_cvs_to_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the CVs to import"])},
  "ai_cvs_to_import_decription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select the CVs to import and then the AI will extract the main data from the CV such as: Name, Surname, E-mail, Telephone number, Residence address and prepare them, with the relevant CV, in the candidate form. Select a maximum of ", _interpolate(_named("maxfile")), " files, each weighing less than ", _interpolate(_named("size")), " Mb. Supported formats are ", _interpolate(_named("formats")), "."])},
  "start_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start import"])},
  "single_import_decription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this feature to import just the basic information (first name, last name, and email) of a single application. You will then be able to upload all the other information such as the CV file, residence address, etc. in the application form."])},
  "select_file_to_upload_csv_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the CSV file to be imported"])},
  "error_file_to_upload_ai_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of files uploaded is greater than the allowed limit."])},
  "ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ai"])},
  "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
  "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
  "allowed_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed file types: png, jpg, jpeg."])},
  "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
  "profile_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Details"])},
  "change_avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change avatar"])},
  "hidden_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application hidden by recruiter"])}
}