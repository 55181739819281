import axios, { AxiosRequestConfig } from "axios";
import { useAuthStore } from "@/store/AuthStore";
import AuthService from "@/core/services/AuthService";
import { Router } from "vue-router";

const ApiService = {
  _requestInterceptor: 0,
  _401interceptor: 0,
  router: null as Router | null,

  init(baseURL: string, router: Router) {
    axios.defaults.baseURL = baseURL;
    this.router = router;
  },

  setHeader(token: string) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource: string) {
    return axios.get(resource);
  },

  post(resource: string, data: any) {
    return axios.post(resource, data);
  },

  put(resource: string, data: any) {
    return axios.put(resource, data);
  },

  delete(resource: string) {
    return axios.delete(resource);
  },

  customRequest(data: AxiosRequestConfig) {
    return axios(data);
  },

  mountRequestInterceptor() {
    this._requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        // add loader
        return config;
      }
    );
  },

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      (response) => {
        // Dismiss loader
        return response;
      },
      async (error) => {
        // Dismiss loader
        const authStore = useAuthStore();

        if (error.request.status === 401) {
          if (error.config.url.includes("api/token/refresh")) {
            authStore.logout();

            if (this.router !== null) {
              this.router.push({ name: "SignIn" });
            }

            throw error;
          } else if (authStore.isLoggedIn()) {
            // Refresh token
            const refreshToken = authStore.refresh_token as string;
            await AuthService.refreshToken(refreshToken);

            // Retry the original request
            return this.customRequest({
              method: error.config.method,
              url: error.config.url,
              data: error.config.data,
            });
          }
        } else if (error.request.status === 403) {
          if (this.router !== null) this.router.push({ name: "Positions" });
        } else if (error.request.status === 404) {
          const conditions = [
            "position",
            "allApplications",
            "moveApplicationsToFolder",
            "application",
          ];
          if (
            this.router !== null &&
            conditions.some((el) => error.response.config.data.includes(el))
          ) {
            this.router.push({ name: "Positions" });
          }
        }
        throw error;
      }
    );
  },
};

export default ApiService;
