import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";
import { useConfigStore } from "@/store/ConfigStore";
import { useAuthStore } from "@/store/AuthStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/positions",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/positions",
        name: "Positions",
        component: () => import("@/views/Positions.vue"),
      },
    ],
  },
  {
    path: "/applications",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/:id/applications/status/:status",
        name: "Applications",
        component: () => import("@/views/Applications.vue"),
      },
      {
        path: "/:id/applications/pipeline/:pipeline",
        name: "ApplicationsPipeline",
        component: () => import("@/views/Applications.vue"),
      },
    ],
  },
  {
    path: "/profile-settings",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/profile-settings",
        name: "/profile-settings",
        component: () => import("@/views/account/ProfileSettings.vue"),
      },
    ],
  },
  {
    path: "/sign-in",
    name: "SignIn",
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      const authStore = useAuthStore();
      if (authStore.isLoggedIn()) {
        next({ name: "Positions" });
      } else {
        next();
      }
    },
    component: () => import("@/views/auth/SignIn.vue"),
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/auth/PasswordReset.vue"),
  },
  {
    path: "/set-password/:hash",
    name: "set-password",
    component: () => import("@/views/auth/SetPassword.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state

  const configStore = useConfigStore();
  configStore.resetLayoutConfig();

  const authStore = useAuthStore();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authStore.isLoggedIn()) {
      next({ name: "SignIn" });
    } else {
      next();
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
