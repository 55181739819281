import ApiService from "./ApiService";
import { AxiosRequestConfig } from "axios";
import { User } from "../types/UserType";
import { useAuthStore } from "@/store/AuthStore";

class UserService {
  public static async resetPassword(username: string): Promise<number> {
    const resetPasswordData: AxiosRequestConfig = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: process.env.VUE_APP_AUTH_BASE_URL + "/api/request-reset-password",
      data: {
        username: username,
      },
    };

    try {
      const response = await ApiService.customRequest(resetPasswordData);

      return response.status;
    } catch (error: any) {
      return error.response?.status;
    }
  }

  public static async setPassword(token: string, password: string) {
    const setPasswordData: AxiosRequestConfig = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: process.env.VUE_APP_AUTH_BASE_URL + "/api/reset-password",
      data: {
        token: token,
        password: password,
      },
    };

    try {
      const response = await ApiService.customRequest(setPasswordData);

      return response.status;
    } catch (error: any) {
      return error.response?.status;
    }
  }

  public static async getProfile(): Promise<User> {
    const graphqlQuery = {
      query: `
        query { 
          user { 
            id
            first_name
            last_name
            email
            avatar
          } 
        }`,
    };

    const data: AxiosRequestConfig = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: "",
      data: graphqlQuery,
    };

    const resp = await ApiService.customRequest(data);

    return resp.data.data.user;
  }

  public static async updateProfile(
    firstname: string,
    lastname: string,
    avatar?: string,
    avatarName?: string
  ): Promise<User> {
    const graphqlQuery = {
      query:
        `
        mutation { 
          updateProfile(first_name: "${firstname}", last_name: "${lastname}"` +
        (avatar ? `, avatar: "${avatar}"` : "") +
        (avatarName ? `, avatar_name: "${avatarName}"` : "") +
        `) {
            id
            first_name
            last_name
            email
            avatar
          }
        }`,
    };

    const data: AxiosRequestConfig = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: "",
      data: graphqlQuery,
    };

    const resp = await ApiService.customRequest(data);

    const authStore = useAuthStore();
    authStore.setUser(resp.data.data.updateProfile as User);

    return resp.data.data.updateProfile;
  }
}

export default UserService;
