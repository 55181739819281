export default {
  "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Profil"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
  "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italienisch"])},
  "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positionen"])},
  "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox"])},
  "not_compliant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not compliant"])},
  "choosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählt"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
  "cloned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geklont"])},
  "manual_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuell hinzufügen"])},
  "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
  "table_info_pagination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es werden ", _interpolate(_named("start")), " bis ", _interpolate(_named("end")), " von ", _interpolate(_named("total")), " Einträgen angezeigt"])},
  "table_select_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("numberSelected")), " Zeilen ausgewählt"])},
  "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingehend"])},
  "application_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlossene Bewerbungen"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelaufen"])},
  "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviert"])},
  "position_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position / Unternehmen"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten"])},
  "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
  "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälligkeitsdatum"])},
  "applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerber"])},
  "applicants_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("number")), " Bewerber"])},
  "timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timing"])},
  "all_applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Bewerber"])},
  "pipeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pipeline"])},
  "of_the_time_has_passed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), "% der Zeit ist vergangen"])},
  "current_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Position"])},
  "job_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Positionen"])},
  "position_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positionsdetail"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen"])},
  "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen"])},
  "total_applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Bewerber"])},
  "share_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Abschnitt finden Sie die Tools, mit denen Sie die Stelle auf anderen Plattformen und Job Börsen veröffentlichen und teilen können."])},
  "complete_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständige URL"])},
  "short_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz-URL"])},
  "copy_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL kopieren"])},
  "qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-Code"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigt"])},
  "url_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die URL wurde in die Zwischenablage kopiert!"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnung"])},
  "add_applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerber hinzufügen"])},
  "manual_add_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie manuell Kontakte hinzu, die an dieser Stelle interessiert sein könnten, um sie zu einer späteren Bewerbung einzuladen."])},
  "csv_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Bewerbung einladen"])},
  "csv_import_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren Sie eine Liste von Kontakten, die massiv eingeladen werden sollen, sich um diese Stelle zu bewerben."])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "add_and_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen und schließen"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hinzufügen"])},
  "select_file_to_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die hochzuladende Datei"])},
  "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladen"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
  "operation_successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation erfolgreich"])},
  "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen"])},
  "success_text_invite_to_apply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eingeladen: ", _interpolate(_named("invited")), ", Nicht eingeladen: ", _interpolate(_named("notInvited")), ", Dupliziert: ", _interpolate(_named("duplicated"))])},
  "not_in_pipeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht in der Pipeline"])},
  "move_in_pipeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Pipeline bewegen"])},
  "invite_to_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladen zum Abschließen der Bewerbung"])},
  "invite_to_video_interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladung zum Video-Interview"])},
  "applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerber"])},
  "application_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbungsdatum"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung"])},
  "back_to_candidates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zu den Bewerbern"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "bulk_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den/die Bewerber und eine Aktion aus"])},
  "data_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datensichtbarkeit"])},
  "internal_dataset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Dataset"])},
  "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragen"])},
  "questions_max_tolal_score": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Antworten (max. ", _interpolate(_named("maxScore")), " Pkt.)"])},
  "single_question_score": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("score")), " Pkte."])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte"])},
  "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "previews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschauen"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "not_compiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht ausgefüllt"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herunterladen"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
  "missing_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Datei"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste"])},
  "error_create_application_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bewerbung existiert bereits"])},
  "error_create_application_position_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellung der Bewerbung: Archivierte Position"])},
  "error_create_application_invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellung der Bewerbung: Ungültige E-Mail"])},
  "error_create_application_in_deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bewerbung wird storniert"])},
  "error_application_move_in_pipeline_missing_mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlender obligatorischer Schritt in der Pipeline"])},
  "error_application_update_field_required_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Wert kann nicht Null sein"])},
  "error_application_update_field_external_invitation_present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Feld kann nicht aktualisiert werden, wenn es eine externe Einladung gibt"])},
  "error_application_update_file_field_missing_argument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kann nicht nur Dateiname oder nur Base64-Datei übergeben werden"])},
  "error_generic_wrong_base_64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base64-Datei enthält keinen Base64-Inhalt"])},
  "invalid_username_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Benutzername oder Passwort"])},
  "profile_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil aktualisiert"])},
  "forgot_password_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Vorgang wurde erfolgreich abgeschlossen. Sie erhalten Anweisungen zur Wiederherstellung Ihres Passworts an die E-Mail-Adresse, mit der Sie registriert wurden"])},
  "set_new_password_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation erfolgreich, Ihr Passwort wurde erfolgreich festgelegt"])},
  "password_and_password_confirmation are_different": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort und Passwortbestätigung sind unterschiedlich"])},
  "invalid_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Passwort"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "forgot_password_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
  "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte warten"])},
  "enter_your_email_to_reset_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
  "setup_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort einrichten"])},
  "choose_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihr neues Passwort: Es muss mindestens 7 Zeichen enthalten und mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und ein Sonderzeichen."])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
  "warning_min_selected_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte wählen Sie mindestens ", _interpolate(_named("number")), " Bewerbung(en) aus!"])},
  "recruiting_inviting_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rekrutierung zugewiesen von ", _interpolate(_named("recruiter")), " (", _interpolate(_named("email")), ")."])},
  "no_recruitment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sieht so aus, als ob Ihnen derzeit keine Rekrutierung zugewiesen ist. Nehmen Sie es leicht."])},
  "no_recruitment_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir werden Sie umgehend benachrichtigen, sobald Sie einen neuen Auftrag erhalten."])},
  "warning_pipeline_automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Schritt verfügt über einen Pipeline-Automatisierungsprozess"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortfahren"])},
  "selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl"])},
  "404_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier scheint es nichts zu geben."])},
  "404_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Dashboard"])},
  "success_text_import_csv": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gesamtzahl der Benutzer: ", _interpolate(_named("total")), ", Importiert: ", _interpolate(_named("imported")), ", Ungültige Zeilen: ", _interpolate(_named("invalid")), ", Dupliziert: ", _interpolate(_named("duplicated")), ", bei Löschung: ", _interpolate(_named("deletion"))])},
  "label_radio_single_insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelner Eintrag"])},
  "label_radio_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV-Import"])},
  "text_create_applications_from_file_download_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie diese Funktion, um Bewerbungen ohne Lebensläufe, aber mit verknüpften Datasets importieren zu können. Wählen Sie die hochzuladende Datei aus (*.csv, max. 1000 Zeilen, Name, Nachname und E-Mail erforderlich). Sie müssen das Template unter dem unten stehenden Link verwenden, um auf die Positionsfelder zu verweisen."])},
  "link_download_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel herunterladen"])},
  "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren"])},
  "import_and_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren und schließen"])},
  "text_invite_by_file_download_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die hochzuladende Datei (*.csv, max. 1000 Zeilen, Vor- und Nachname optional, E-Mail erforderlich)"])},
  "link_download_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modell herunterladen"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "quick_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick apply"])},
  "invalid_reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegebene E-Mail ist nicht registriert"])},
  "already_request_reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage bereits gesendet, überprüfen Sie Ihre E-Mail"])},
  "delete_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbungen löschen"])},
  "success_text_delete_applicants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gesamt: ", _interpolate(_named("total")), ", Gelöscht: ", _interpolate(_named("deleted")), ", Nicht gelöscht: ", _interpolate(_named("not_deleted")), ".<br> Bewerbungen, die aus einem anderen Kontext stammen, können nicht gelöscht werden"])},
  "are_you_sure_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher?"])},
  "delete_confirmation_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geben Sie das Wort „", _interpolate(_named("delete")), "“ ein, um fortzufahren"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["löschen"])},
  "wrong_input_word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das eingegebene Wort ist falsch"])},
  "delection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschung"])},
  "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warten"])},
  "elaborating_action_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bearbeiten die Aktion..."])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "loading_completed_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Laden ist abgeschlossen. Sie erhalten eine E-Mail, wenn alle Dateien verarbeitet worden sind."])},
  "uploaded_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochgeladen:"])},
  "error_colon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler:"])},
  "importing_three_dots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import im Gange..."])},
  "ai_import_progress_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warten Sie, bis alle Lebensläufe, die Sie für diesen Import ausgewählt haben, geladen sind; Sobald Sie fertig sind, können Sie dieses Fenster schließen und weiterarbeiten. Über den Abschluss des folgenden Imports werden Sie per E-Mail benachrichtigt."])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "wait_three_dots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte warten..."])},
  "ai_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-Import"])},
  "select_cvs_to_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die zu importierenden Lebensläufe aus"])},
  "ai_cvs_to_import_decription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wählen Sie die zu importierenden Lebensläufe aus und dann extrahiert die KI die wichtigsten Daten aus dem Lebenslauf, wie z. B. Name, Nachname, E-Mail, Telefonnummer, Anschrift, und bereitet sie zusammen mit dem entsprechenden Lebenslauf im Kandidatenprofil vor. Wählen Sie maximal ", _interpolate(_named("maxfile")), " Dateien aus, die jeweils weniger als ", _interpolate(_named("size")), " MB wiegen. Unterstützte Formate sind ", _interpolate(_named("formats")), "."])},
  "start_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import starten"])},
  "single_import_decription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie diese Funktion, um nur die grundlegenden Informationen (Name, Nachname und E-Mail) einer einzelnen Bewerbung zu importieren. Anschließend können Sie alle weiteren Informationen wie Lebenslauf, Anschrift usw. in das Bewerbungsprofil hochladen."])},
  "select_file_to_upload_csv_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die zu importierende CSV-Datei aus"])},
  "error_file_to_upload_ai_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl der hochgeladenen Dateien ist größer als das zulässige Limit."])},
  "ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ai"])},
  "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
  "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständiger Name"])},
  "allowed_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubte Dateitypen: png, jpg, jpeg."])},
  "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
  "profile_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil-Details"])},
  "change_avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar ändern"])},
  "hidden_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbung vom Recruiter ausgeblendet"])}
}