import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import ElementPlus from "element-plus";
import { createI18n } from "vue-i18n/index";
import Toast, { TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { useAuthStore } from "./store/AuthStore";
import { useLocaleStore } from "./store/LocaleStore";

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import UserService from "./core/services/UserService";

import { config as faConfig, library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { Language } from "./store/LocaleStore";

import "@/core/plugins/prismjs";

import { createGtm } from "@gtm-support/vue-gtm";

const app = createApp(App);

const options = {
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.ERROR]: {
      position: "top-right",
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false,
    },
    [TYPE.SUCCESS]: {
      position: "top-right",
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false,
    },
  },
};

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);
app.use(ElementPlus);
app.use(Toast, options);
app.directive("clickOutside", {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

ApiService.init(process.env.VUE_APP_GRAPHQL_BASE_URL, router);
const authStore = useAuthStore();
if (authStore.isLoggedIn() && authStore.access_token) {
  ApiService.setHeader(authStore.access_token);
  UserService.getProfile().then((res) => {
    authStore.setUser(res);
  });
}

ApiService.mountRequestInterceptor();
ApiService.mount401Interceptor();

initInlineSvg(app);
initVeeValidate();

const localeStore = useLocaleStore();
if (Object.keys(localeStore.language).length === 0) {
  const initLang = {
    locale: "en",
    name: "English",
    flag: "media/flags/united-states.svg",
  } as Language;

  localeStore.setLanguage(initLang);
}

// import i18n resources
import en from "./core/translations/en.json";
import it from "./core/translations/it.json";
import de from "./core/translations/de.json";

const i18n = createI18n({
  legacy: false,
  locale: localeStore.getCurrentLocale() || "en",
  globalInjection: true,
  messages: {
    en,
    it,
    de,
  },
  fallbackLocale: "en",
});

app.use(i18n);

// Fontawesome
faConfig.autoAddCss = false; // https://fontawesome.com/v5/docs/web/other-topics/security
require("@fortawesome/fontawesome-svg-core/styles.css");

import {
  faCircle,
  faArrowRightLong,
  faUsers as faSolidUsers,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faMagnifyingGlass,
  faUsers,
  faFilter,
  faUserPlus,
  faXmark,
  faFileCsv,
  faPencil,
  faLock,
  faFileInvoice,
  faCalendarCheck,
  faTrophy,
  faGrid2,
  faDiagramCells,
  faClipboard,
  faShareNodes,
  faWandMagicSparkles,
  faHourglass,
  faInbox,
  faUserXmark,
  faClone,
  faCheck,
  faSpinnerThird,
  faTrash,
  faFlagCheckered,
  faEyeSlash,
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faCircleHalfStroke,
  faCircle as faCircleRegular,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
  faCircle,
  faMagnifyingGlass,
  faUsers,
  faFilter,
  faUserPlus,
  faArrowRightLong,
  faCircleHalfStroke,
  faXmark,
  faFileCsv,
  faPencil,
  faLock,
  faFileInvoice,
  faCalendarCheck,
  faTrophy,
  faGrid2,
  faDiagramCells,
  faClipboard,
  faShareNodes,
  faSolidUsers,
  faWandMagicSparkles,
  faHourglass,
  faInbox,
  faUserXmark,
  faClone,
  faCheck,
  faCircleRegular,
  faSpinnerThird,
  faTrash,
  faFlagCheckered,
  faEyeSlash
);

app.component("font-awesome-icon", FontAwesomeIcon);

// GTM
const gtm = createGtm({
  id: process.env.VUE_APP_GTM_ID,
  defer: true,
  vueRouter: router,
  debug: process.env.VUE_APP_DEBUG_GTM === "true",
  enabled: process.env.VUE_APP_ENABLE_GTM === "true",
});

app.use(gtm);

app.mount("#app");
