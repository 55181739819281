import { defineStore } from "pinia";
import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";
import layoutConfig from "@/core/config/DefaultLayoutConfig";
import objectPath from "object-path";
import merge from "deepmerge";

export const useConfigStore = defineStore("config", {
  state: () => {
    return {
      config: layoutConfig,
      initial: layoutConfig,
    };
  },
  getters: {
    getLayoutConfig(state) {
      return (path?, defaultValue?) => {
        return objectPath.get(state.config, path, defaultValue);
      };
    },
  },
  actions: {
    setLayoutConfig(payload: LayoutConfigTypes) {
      this.config = payload;
    },
    resetLayoutConfig() {
      this.config = Object.assign({}, this.initial);
    },
    overrideLayoutConfig() {
      this.config = this.initial = Object.assign(
        {},
        this.initial,
        JSON.parse(window.localStorage.getItem("config") || "{}")
      );
    },
    overridePageLayoutConfig(payload: LayoutConfigTypes) {
      this.config = merge(this.config, payload);
    },
  },
});
