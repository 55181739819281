import { defineStore } from "pinia";

export interface Language {
  locale: string;
  name: string;
  flag: string;
}

export const useLocaleStore = defineStore("locale", {
  state: () => {
    return {
      language: {} as Language,
    };
  },
  actions: {
    setLanguage(language: Language): void {
      this.language = language;
    },
    setLocale(locale: string): void {
      this.language.locale = locale;
    },
    getCurrentLocale(): string {
      return this.language && this.language.locale
        ? this.language.locale
        : "en";
    },
  },
  persist: true,
});
